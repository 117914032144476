
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs/internal/observable/of';
import { shareReplay } from 'rxjs/internal/operators/shareReplay';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { tap } from 'rxjs/internal/operators/tap';
import { Subject } from 'rxjs/internal/Subject';
// Api service
import { ApiServ, InitServ, UtilServ } from 'src/app/Services';

interface CacheConfig {
	ttl?: number;
	isForced?: boolean;
	apiCallback: () => Observable<any>;
}
@Injectable({
	providedIn: 'root'
})
export class ApiRespCacheServ {

	private destroy = new Subject<void>();
	private apiLoadStatus: { bkngCustData: boolean, liveReviews: boolean } = {
		bkngCustData: false,
		liveReviews: false
	};
	private reviewsSubject = new BehaviorSubject<any>(null); // Initial value null
	private bkngCustomzSubject = new BehaviorSubject<any>(null); // Initial value null

	cache = new Map<string, { data: any; expiry: number }>();
	private maxCacheSize = 50; // stack store upto 50 key-data
	private readonly maxTTLTime = 2 * 60 * 60 * 1000; // 2hrs default storage cache
	private readonly autoClearCache: number = 60 * 60 * 1000; // 1 hr default timer

	constructor(private apiServ: ApiServ, private initServ: InitServ, private utilServ: UtilServ) {
		// automatically clear `[expired]` cache entries at a specified time interval.
		setInterval(() => this.clearExpiredCache(), this.autoClearCache); // Auto-clear run 1 hr
	}

	/**
	 * Fetches live reviews with a specified limit.
	 * Ensures the API is called only once by checking the loading status.
	 * Uses `shareReplay(1)` to cache the response and `takeUntil(this.destroy)` to handle unsubscription.
	 * Updates the reviewsSubject with the fetched data.
	 */
	public getLiveReviewsApi(limit: number): Observable<any> {
		if (!this.apiLoadStatus.liveReviews) {
			this.apiLoadStatus.liveReviews = true;
			this.apiServ.callApiWithQueryParams('GET', 'Reviews', { limit }).pipe(takeUntil(this.destroy), shareReplay(1)).subscribe((resp: any) => {
				let data = this.apiServ.checkAPIRes(resp) && resp?.data?.length > 0 ? resp.data : null;
				this.reviewsSubject.next(data);
			});
		}
		return this.reviewsSubject.asObservable();
	}

	public getBkngCustomzDataApi(): Observable<any> {
		if (!this.apiLoadStatus.bkngCustData) {
			this.apiLoadStatus.bkngCustData = true;
			let queryParams: any = { theme_slug: this.initServ.theme, language: this.initServ.savedLng, mode: 'live' };
			if (this.initServ.theme) {
				queryParams['mode'] = 'preview';
			}
			this.apiServ.callApiWithQueryParams('GET', 'BkngSummayCustomization', queryParams).pipe(takeUntil(this.destroy), shareReplay(1)).toPromise().then((resp: any) => {
				let data = this.apiServ.checkAPIRes(resp) && resp?.data ? this.utilServ.buildBkngCustomz(resp.data) : null;
				this.bkngCustomzSubject.next(data);
			});
		}
		// console.log('hghjkghkgdghfj', this.bkngCustomzSubject.asObservable());
		return this.bkngCustomzSubject.asObservable();
	}

	/**
	 * Sets data in the cache for a given key, with an optional time-to-live (TTL).
	 * If the TTL expires, the cached data will be automatically evicted.
	 * It also checks if eviction is needed before setting the new cache entry.
	 *
	 * @param {string} key - The unique key under which the data will be stored in the cache.
	 * @param {any} data - The data to be stored in the cache.
	 * @param {number} ttl - The time-to-live for the cache entry in milliseconds (optional, defaults to this.maxTTLTime).
	 *
	 * @use Usage:
	 *
	 * Setting cache with a TTL of 1 hour (3600 seconds = 1 hour)
	 * this.setCache('someKey', someData, 3600000);
	 */
	public setCache(key: string, data: any, ttl: number = this.maxTTLTime): void {
		this.evictIfNeeded(); // Check and evict old cache entries if needed before adding new ones
		const expiry = Date.now() + ttl; // Calculate the expiration time by adding TTL to the current time
		this.cache.set(key, { data, expiry }); // Store the data in the cache along with the calculated expiry time
	}

	/**
	 * Retrieves data from the cache for a given key, returning the cached data only if it is not expired.
	 * If the cache entry has expired, it returns null.
	 *
	 * @param {string} key - The unique key of the cached entry.
	 * @returns {any | null} - Returns the cached data if it exists and is not expired, otherwise null.
	 */
	public getCache(key: string): any | null {
		// Get the cached item for the given key
		const cachedItem = this.cache.get(key);
		// Return the cached data if it exists and has not expired, otherwise return null
		return cachedItem && cachedItem.expiry > Date.now()
			? cachedItem.data
			: null;
	}

	removeCache(key:string){
		this.cache.delete(key);
	}

	/**
	 * Clears expired cache entries. This method iterates through all cache entries
	 * and deletes the ones that have passed their expiration time.
	 * It logs the remaining cache after clearing expired entries.
	 *
	 * Usage:
	 *
	 * * Automatically clears expired cache entries
	 * this.clearExpiredCache();
	 */
	private clearExpiredCache(): void {
		const now = Date.now();
		// Iterate over each cache entry and delete those that have expired
		this.cache.forEach((value, key) => {
			// Remove expired cache entries
			if (value.expiry < now) this.cache.delete(key);
		});
	}

	/**
	 * Fetches data with caching logic to optimize API calls.
	 * It first checks if the requested data is already cached, and if so, returns it from the cache.
	 * If the data is not in the cache or the `isForced` flag is set to true, it calls the provided API callback to fetch the data,
	 * then caches the result for future use based on the provided TTL (Time-To-Live).
	 *
	 * @param {string} key - The unique key to identify the cache entry.
	 * @param {CacheConfig} cachedConfig - The configuration object containing cache settings.
	 * @returns {Observable<any>} - Returns an observable that emits the fetched or cached data.
	 *
	 * Usage:
	 *
	 * const cacheConfig: CacheConfig = {
	 *     ttl: 3600,													// Optional: Time-to-live in seconds, default is this.maxTTLTime: 2hrs
	 *     isForced: false,											// Optional: If true, forces fetching new data from the API even if cached data exists
	 *     apiCallback: () => apiService.callApi( ... )	// The callback function that fetches data from the API
	 * };
	 *
	 * fetchDataWithCache('someKey', cacheConfig).subscribe(data => {
	 *     console.log(data); // The data is either fetched from the cache or from the API
	 * });
	 */
	public fetchDataWithCache(key: string, cachedConfig: CacheConfig): Observable<any> {
		const { ttl = this.maxTTLTime, isForced = false, apiCallback } = cachedConfig;
		const cachedData = this.getCache(key);
		if (cachedData && !isForced) {
			return of(JSON.parse(JSON.stringify(cachedData))); // return cloned data as pagination will return the last item
		} else {
			return apiCallback().pipe(tap((data) => this.setCache(key, JSON.parse(JSON.stringify(data)), ttl)));
		}
	}

	/**
	 * Evicts (Removal of the property) the first cache entry if the cache exceeds the maximum size.
	 * This function ensures that the cache does not exceed the maximum allowed size by removing the first item in the cache.
	 */
	private evictIfNeeded(): void {
		if (this.cache.size <= this.maxCacheSize) { return; }
		// If cache size exceeds the maximum, proceed with eviction
		const firstKey = this.cache.keys().next().value;
		if (firstKey) { this.cache.delete(firstKey); }
	}

	/**
	 * Method creates a cached API response object based on the input response object.
	 * @param {any} respObj - The `respObj` parameter in the `createCachedApiResp` function is an object
	 * that contains the following properties:
	 * @returns The function `createCachedApiResp` is returning a modified response object `resp` with the
	 * following properties:
	 * - `api_status`: The value from `respObj.api_status`
	 * - `code`: The value from `respObj.code`
	 * - `data`: The value from `respObj.data`
	 * - `total_record`: The length of `respObj.data` if it exists, otherwise 0
	 */
	public createCachedApiResp(respObj: any): any {
		const resp: any = {
			api_status: respObj.api_status,
			code: respObj.code,
			data: respObj.data,
			total_record: respObj.data?.length ?? 0,
			is_cached: true
		}
		return resp
	}
}
