<div *ngIf="secId" class="tjs-dashboard py-30 py-md-60" [id]="secId">
	<div class="container">
		<!-- Profile card -->
		<bk-profile-card type="dashboard" *ngIf="renderProfile" [booking]="(bookingData.data && bookingData.data?.bookings?.length > 0) ? bookingData.data?.bookings?.[0] : null" [section]="section?.profile"></bk-profile-card>
		<!-- Tabs -->
		<div class="" [id]="section?.appointments?.id">
			<div class="row flex-lg-row flex-column-reverse tjs-appointment__wrap">
				<div class="col-12 col-lg-9 mb-lg-0">
					<div class="tjs-tab tjs-tab--box tjs-tab--box--lg">
						<ul class="tjs-list" *ngIf="tabs && tabs.length > 0">
							<li *ngFor="let tab of tabs; trackBy: utilServ.trackByFnIndex" [class.active]="active == tab?.slug">
								<button class="tjs-tab__link" (click)="getUserBkngs(tab?.slug, true)" [ngClass]="(active == tab?.slug) ? 'bk-active-tab': 'bk-tab'">{{ (section?.appointments?.[tab?.slug]?.label) }}</button>
							</li>
						</ul>
					</div>
				</div>
				<div class="col-12 col-lg-3 text-lg-end mb-lg-0 mb-25">
					<div class="d-grid d-md-flex justify-content-md-end">
						<bk-navigate *ngIf="multiIndusStatus; else single" [secId]="section?.appointments?.add_bkng_btn_id" [designId]="section?.appointments?.add_bkng_btn?.design_id" innerHTML="<i class='tjsicon-add me-10'></i>{{(section?.appointments?.add_bkng_btn?.content)}}" customClass="btn btn-primary" essentialClass="w-100 px-xl-30 px-20 py-15" (callback)="industriesPopup()" ></bk-navigate>
						<ng-template #single>
							<bk-navigate [secId]="section?.appointments?.add_bkng_btn_id" [designId]="section?.appointments?.add_bkng_btn?.design_id" [isLinkBtn]="true" [routeLink]="['/'+initServ.appDynamicRoutes['booknow']]" innerHTML="<i class='tjsicon-add me-10'></i>{{(section?.appointments?.add_bkng_btn.content)}}" customClass="btn btn-primary" essentialClass="px-xl-30 px-20 py-15 w-100"></bk-navigate>
						</ng-template>
					</div>
				</div>
			</div>
			<div class="card bk-booking-card-wrap position-relative" [id]="active">
				<div class="card-body">
					<ng-container *ngIf="renderProfile && bookingData.data?.bookings?.length > 0; else noData">
						<!-- Bookings -->
						<div>
							<bk-booking-listing [tabs]="section?.appointments" [status]="active" [bookings]="bookingData.data.bookings" [bkngUsers]="bookingData.data?.user_info" (refreshComp)="refreshComp($event)"></bk-booking-listing>
						</div>
						<!-- View all link -->
						<div *ngIf="showLoadMore" class="text-end mt-10">
							<bk-navigate [secSett]="section?.appointments?.view_all_btn" [secId]="section?.appointments?.view_all_btn_id" [designId]="section?.appointments?.view_all_btn?.design_id" [isLinkBtn]="true" [routeLink]="[viewAllRoute]" innerHTML="{{(section?.appointments?.view_all_btn?.content)}}" customClass="btn btn-primary"></bk-navigate>
						</div>
					</ng-container>
					<ng-template #noData>
						<ng-container [ngSwitch]="active">
							<h4 *ngSwitchCase="'upcoming'" class="mb-0 fw-bold bk-active--color" translate>You have no upcoming appointments.</h4>
							<h4 *ngSwitchCase="'completed'" class="mb-0 fw-bold bk-active--color" translate>There are no completed appointments.</h4>
							<h4 *ngSwitchCase="'cancelled'" class="mb-0 fw-bold bk-active--color" translate>No cancellations were made yet.</h4>
							<h4 *ngSwitchCase="'declined'" class="mb-0 fw-bold bk-active--color" translate>There are no declined charges at this time.</h4>
						</ng-container>
					</ng-template>
				</div>
				<!-- Loader -->
				<bk-app-loader [loaderId]="loaderId"></bk-app-loader>
			</div>
		</div>
	</div>
</div>
