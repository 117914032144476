import { Component, OnInit, ViewEncapsulation, Self, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { takeUntil } from "rxjs/operators";
// Services
import { NgOnDestroy, UtilServ, ApiServ, LoaderServ, PopupServ, InitServ, SectionServ, RenderComponentServ, ApiRespCacheServ } from '../Services';

@Component({
	selector: 'bk-dashboard',
	templateUrl: './Dashboard.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class DashboardComponent implements OnInit {
	// Variables
	tabs: any[] = [
		{ name: 'Appointments', slug: 'upcoming' },
		{ name: 'Previous appointments', slug: 'completed'},
		{ name: 'Canceled appointments', slug: 'cancelled'},
		{ name: 'Declined payments', slug: 'declined'}
	]
	active: string = 'upcoming';
	limit: number = 9;
	page: number = 1;
	bookings: any;
	loaderId: string = 'upcoming-loader';
	multiIndusStatus: boolean = false;
	renderProfile: boolean = true;
	viewAllRoute: string = '/appointments';
	showLoadMore: boolean = false;
	//
	secId: any;
	section: any = { profile: null, appointments: null };

	bookingData:any = {
		data: {},
		total_record: 0,
		// status: false,
		slug: ''
	}
	isForcedFetch:boolean = false;

	// eslint-disable-next-line max-params
	constructor(@Self() public destroy: NgOnDestroy, public actRoute: ActivatedRoute, public router: Router, public utilServ: UtilServ, public apiServ: ApiServ, public loader: LoaderServ, public popupServ: PopupServ, public initServ: InitServ, public rcServ: RenderComponentServ, public secServ: SectionServ, private cDRef: ChangeDetectorRef, public apiRespCacheServ: ApiRespCacheServ) {
		// TODO implement cache service rendering issue
		// this.apiRespCacheServ.getBkngCustomzDataApi().subscribe(data => {
		// 	if(data){
		// 		console.log('resharing', data);
		// 		this.initServ.bkngCustomzData = data;
		// 		this.refreshComp(false);
		// 		this.cDRef.detectChanges();
		// 	}
		// });
		this.initServ.bookingSpots().then(() => {
			this.refreshComp(false);
			this.cDRef.detectChanges();
		});
		this.initServ.loadBkngCustData().then(() => {
			this.refreshComp(false);
			this.cDRef.detectChanges();
		});

		// Multi industry
		let multiIndus: any = this.utilServ.multiIndustriesStatus();
		this.multiIndusStatus = multiIndus?.multiIndus;
	}

	ngOnInit(): void {
		// Build section data
		if (this.secId && this.rcServ.pageData) {
			let pageSett:any = this.rcServ.pageData.section_settings; // page settings
			this.secServ.setServData(pageSett, this.rcServ.pageData.content);
			this.section = this.secServ.buildSectionFields(this.secId, this.section, this.rcServ.pageData);
		}
		// URL Fragment, based this active tab works, other wise default.
		let fragment = this.actRoute.snapshot.fragment ?? this.active;
		// get bookings data based on the fragment or active status slug
		this.getUserBkngs(fragment);
	}

	/**
	 * Sets the active booking type and updates the view route based on the booking type.
	 * It also triggers the appropriate method to fetch bookings for the selected type.
	 * @param type - The booking type ('upcoming', 'completed', 'cancelled', 'declined'). Default is 'upcoming'.
	 * @param isNavigate - A flag indicating whether to navigate to the new route (defaults to false).
	 */
	public getUserBkngs(type: string = 'upcoming', isNavigate: boolean = false): void {
		// Set the active booking type and determine the corresponding route.
		this.active = type;
		this.viewAllRoute = this.getActRoute(type);
		// Update the route if navigation is required.
		this.updateActRoute(isNavigate, type);
		// Define the actions for each booking type, including the slug and the method to call.
		let bkngActions: any = {
			upcoming: { slug: 'upcoming', method: this.fetchBookingsWithCache.bind(this) },
			completed: { slug: 'completed', method: this.fetchBookingsWithCache.bind(this) },
			cancelled: { slug: 'cancelled', method: this.fetchBookingsWithCache.bind(this) },
			declined: { slug: 'declined', method: this.fetchBookingsWithCache.bind(this) }
		}
		// Execute the method for the selected booking type.
		let action = bkngActions[type];
		if (action) {
			action.method(action.slug);
		}
	}

	/**
	 * Retrieves the route path based on the booking type.
	 * @param type - The booking type.
	 * @returns The corresponding route path.
	 */
	private getActRoute(type: string) {
		let route: Record<string, string> = {
			upcoming: '/appointments',
			completed: '/appointments/previous',
			cancelled: '/appointments/canceled',
			declined: '/appointments/declined-payments',
		}
		return route[type] || route.upcoming
	}

	/**
	 * Updates the active route based on the booking status.
	 * @param navigate - Whether to navigate to the new route.
	 * @param status - The booking status.
	 */
	private updateActRoute(navigate: boolean = false, status: string = '') {
		if (navigate && this.router?.url) {
			let route = this.utilServ.getUrlWithoutFragment(this.router.url);
			this.router.navigateByUrl(`${route}#${status}`);
		}
	}

	/**
	 * Retrieves bookings based on the provided booking type slug.
	 * This method checks if the data is available in the cache and fetches it from the API if necessary.
	 * Once the data is retrieved, the response is passed to the handler method.
	 * @param slug - The booking type slug that defines which bookings to retrieve (e.g., 'upcoming', 'completed', 'cancelled', 'declined').
	 */
	private fetchBookingsWithCache(slug: string): void {
		// Check if the booking data for the specified type is in cache;
		// if not, initiate an API request to fetch the data.
		const sub = this.apiRespCacheServ.fetchDataWithCache(slug, { apiCallback: () => this.getBkngsApi(slug), isForced: this.isForcedFetch });
		// subscribe the response
		sub.subscribe((resp: any) => this.getBkngsApiResp(resp, slug));
	}

	/**
	 * Constructs the API request for fetching bookings based on the slug.
	 * @param slug - The booking status.
	 * @returns An observable of the API response.
	 */
	private getBkngsApi(slug: string) {
		this.loaderId = `${slug}-loader`;
		let queryParams = { status: slug, limit: this.limit, page: this.page };
		return this.apiServ.callApiWithPathQueryVars('GET', 'Bookings', [this.utilServ.userId()], queryParams).pipe(takeUntil(this.destroy));
	}

	private getBkngsApiResp(resp: any, slug: string): void {
		let bookings = resp?.data?.bookings;
		this.bookingData.data = null;
		if(this.apiServ.checkAPIRes(resp) && this.utilServ.checkArrLength(bookings)){
			this.bookingData.data = resp.data;
			this.bookingData.slug = slug;
			this.bookingData.total_record = bookings.length || 0;
			// Updates the list of items and determines whether to show a "Load More" button based on the limit.
			let loaMoreData = this.utilServ.updateLoadMoreItems(bookings, [], this.limit);

			this.bookingData.data.bookings = loaMoreData.items;
			this.showLoadMore = loaMoreData.showLoadMore;
		}

		// reset
		this.isForcedFetch = false;
		this.loader.hide(this.loaderId);
	}

	/**
	 * Industries popup
	 */
	public industriesPopup(): void {
		this.popupServ.industriesPopup();
	}
	/**
	 * Refresh component
	 * @param status
	 */
	public refreshComp(status: boolean): void {
		this.renderProfile = false;
		if (status) {
			this.isForcedFetch = true;
			this.getUserBkngs(this.active)
		}
		setTimeout(() => {
			this.renderProfile = true;
			this.loader.hide(this.loaderId);
		}, 0);
	}
}
